/* eslint-disable react/prop-types */
import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CateloguePage from "./CataloguePage/CateloguePage"

import "assets/scss/material-kit-react.scss?v=1.8.0"

const Catalogue = ({ location, data }) => {
  const params = new URLSearchParams(location.search)
  const url = location.href ? location.href : ""
  return (
    <Layout>
      <SEO
        title={"Catalogues: " + data.contentfulLeaflet.title}
        description={"Best Deals: " + data.contentfulLeaflet.title}
      />
      <CateloguePage
        brand={data.contentfulLeaflet.brand}
        pages={data.contentfulLeaflet.pages}
        name={data.contentfulLeaflet.title}
        description={data.contentfulLeaflet.description}
        url={url}
        tags={data.contentfulLeaflet.tags}
        keywords={params.get("keywords")}
      />
    </Layout>
  )
}

export const query = graphql`
  query LeafletQuery($id: String) {
    contentfulLeaflet(id: { eq: $id }) {
      title
      validFrom
      validTo
      tags
      pages {
        description
        id
        file {
          url
        }
      }
      id
      description {
        childMarkdownRemark {
          html
        }
      }
      locations {
        kilometerRange
        location {
          lat
          lon
        }
      }
    }
  }
`

export default Catalogue
